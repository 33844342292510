<script lang="ts">
	import Steam from './custom-icons/steam.svelte';
	import Discord from './custom-icons/discord.svelte';

	// Import all the required icons
	import {
		AlertTriangle,
		ArrowRight,
		Check,
		ChevronLeft,
		ChevronRight,
		Circle,
		ClipboardCheck,
		Copy,
		CreditCard,
		File,
		FileText,
		HelpCircle,
		Image,
		Bell,
		Lock,
		Laptop,
		Loader,
		Loader2,
		Moon,
		MoreVertical,
		Pizza,
		Plus,
		Settings,
		SunMedium,
		Trash,
		Twitter,
		User,
		X,
		ChevronDown,
		Shield,
		LogOut
	} from 'lucide-svelte';

	interface Props {
		name?: string;
		[key: string]: any;
	}

	let { name = '', ...rest }: Props = $props();

	type IconComponent = {
		[key: string]: any;
	};

	let components: IconComponent = {
		close: X,
		spinner: Loader2,
		spinner2: Loader,
		chevronLeft: ChevronLeft,
		chevronRight: ChevronRight,
		chevrondown: ChevronDown,
		trash: Trash,
		post: FileText,
		page: File,
		media: Image,
		settings: Settings,
		billing: CreditCard,
		ellipsis: MoreVertical,
		add: Plus,
		warning: AlertTriangle,
		user: User,
		arrowRight: ArrowRight,
		help: HelpCircle,
		pizza: Pizza,
		twitter: Twitter,
		check: Check,
		copy: Copy,
		copyDone: ClipboardCheck,
		sun: SunMedium,
		moon: Moon,
		shield: Shield,
		logout: LogOut,
		laptop: Laptop,
		steam: Steam,
		discord: Discord,
		bell: Bell,
		lock: Lock
	};

	const SvelteComponent = $derived(components[name]);
</script>

<SvelteComponent {...rest} />
